.Project {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 20px;
}
.card {
  position: relative;
  height: 30%;
  background-color: #c373e7;
  display: flex;
  width: 35%;
  margin-top: 12%;
  margin-left: 50px;
  border-radius: 20px;
  /* box-shadow: 0 8px 8px 0 rgb(122, 122, 243); */
}
.imgbx {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: 0.5s ease-in-out;
  border-radius: 20px;
}
.fimg {
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
  border-radius: 20px;
}
.card:hover .imgbx {
  width: 250px;
  height: 200px;
  left: -110px;
  top: calc(50% - 100px);
  transition: 0.5s ease-in-out;

}
.project-heading {
  color: white;
  margin-left: 150px;
  padding: 5px;
}
.content p {
  color: white;
  margin-left: 130px;
  text-align: justify;
  margin-top: 10px;
  padding: 20px;
}
.project-heading1 {
  color: white;
  text-align: center;
}
.project-icon {
  background-color: transparent;
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  border: 1px solid white;
  margin-left: 50%;
  margin-top: -20px;
}
.project-icon1 {
  color: black;
  width: 25px;
  height: 25px;
}
.project-icon:hover {
  transform: rotate(35deg);
  transform-origin: bottom;
}

@media screen and (max-width: 500px) {
    .Project{
        flex-direction: column;
    }
    .card:hover .imgbx{
        top: calc(-2% - 100px);
        margin-left: 41%; 
        width: 300px; 
    }
    .card{
        width: 100%;
        margin-left: 5px;
        margin-top: 50%;
    } 
    .content p{
       margin-top: 100px;
       margin-left: -8px;
    }
    .project-icon{
        margin-left: 220px;
    }
       
}
