.resume h3{
    color: #b34ee2;
    margin-left: 53%;
    margin-top: -35%;
    width: 100%;
}
.downloadButton {
    background: transparent;
    color: #fff;
    font-size: 17px;
    text-transform: uppercase;
    font-weight: 600;
    border: none;
    padding: 20px 30px;
    cursor: pointer;
    perspective: 30rem;
    border-radius: 10px;
    margin-left: -50%;
    margin-top: 250%;
}
  
.downloadButton::before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 10px;
    background: linear-gradient(
      320deg,
     #b34ee2    ,
      rgba(128, 0, 128, 0.308)
    );
    z-index: 1;
    transition: background 3s;
}
  
.downloadButton:hover::before {
    animation: rotate 1s;
    transition: all 0.5s;
}
  
@keyframes rotate {
    0% {
      transform: rotateY(180deg);
    }
  
    100% {
      transform: rotateY(360deg);
    }
}
@media screen and (max-width:800px) {
  .resume h3{
    margin-left: 30px;
    margin-top: -200px;
    width: 120%;
  }
  .downloadButton{
    margin-left: -160%;
    margin-top: 600px;
  }
}
  