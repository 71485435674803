.heading h3 {
  color: #b34ee2;
  margin-top: 150px;
  margin-left: 105%;
  width: 120%;
}
.contact {
  height: 100%;
  max-width: 1366px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 140px;
  /* background-color: rgb(32, 32, 25); */
  margin-top: -80px;
  margin-left: 1%;
}
.textcontainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* padding-left: 20px; */
}
.textcontainer h3 {
  color: white;
}
.textcontainer span {
  color: white;
}
.formcontainer {
  flex: 1;
  position: relative;
  width: 40%;
}
.formcontainer form .input-box {
  position: relative;
  width: 100%;
  margin-top: 20px;
}
.formcontainer form .input-box input,
.formcontainer form .input-box textarea {
  width: 150%;
  padding: 5px 0;
  font-size: 1.1em;
  margin: 10px 0;
  border: none;
  background-color: transparent;
  border-bottom: 2px solid #b34ee2;
  color: white;
  outline: none;
  /* resize: none; */
}
.formcontainer form .input-box span {
  position: absolute;
  left: 0;
  pointer-events: none;
  padding: 5px 0;
  margin: -60px 0;
  font-size: 1.1em;
  color: white;
  transition: 0.5s;
}
.formcontainer form .input-box input:focus ~ span,
.formcontainer form .input-box textarea:focus ~ span {
  color: #b34ee2;
  font-size: 0.9em;
  transform: translateY(-24px);
}
/* .submit-btn{
    width: 150px;
    height: 50px;
    margin-left: 100px;
    background-color: transparent;
    border-radius: 30em;
    border: 3px solid #b34ee2;
    font-weight: bold;
    color: white;
    margin-top: 10px;
} */
.submit-btn {
  display: inline-block;
  padding: 6px 24px;
  border-radius: 4px;
  transition: all 0.2s ease-in;
  font-size: 19px;
  cursor: pointer;
  color: white;
  z-index: 1;
  width: 150px;
  height: 50px;
  margin-left: 200px;
  margin-top: 10px;
  background-color: transparent;
  border-radius: 30em;
  border: 3px solid #b34ee2;
}
/* 
.submit-btn:before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%) scaleY(1) scaleX(1.25);
  top: 100%;
  width: 140%;
  height: 180%;
  border-radius: 50%;
  display: block;
  transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
  z-index: -1;
}

.submit-btn:after {
  content: "";
  position: absolute;
  left: 55%;
  transform: translateX(-50%) scaleY(1) scaleX(1.45);
  top: 180%;
  width: 160%;
  height: 190%;
  background-color: #b34ee2;
  border-radius: 50%;
  display: block;
  transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
  z-index: -1;
}

.submit-btn:hover {
  color: #ffffff;
  border: 1px solid #b34ee2;
}

.submit-btn:hover:before {
  top: -35%;
  background-color: #b34ee2;
  transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
}

.submit-btn:hover:after {
  top: -45%;
  background-color: #b34ee2;
  transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
} */

  .icon {
    color: #b34ee2;
    width: 30px;
    height: 30px;
  }
  .icon-div {
    display: flex;
    width: 60px;
    height: 60px;
    margin-left: 220px;
    margin-top: 10px;
  }
.items {
  background-color: black;
  padding: 5px;
  margin: 5px;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid #b34ee2;
}
.items:hover {
  background-color: #b34ee2;
  box-shadow: 0 10px 20px #b34ee2;
}
.icon1 {
  color: white;
  width: 20px;
  height: 20px;
}
@media screen and (max-width: 800px) {
  .heading h3 {
    margin-top: 180px;
    margin-left: 50px;
  }
  .textcontainer {
    margin-left: -150px;
    margin-top: 320px;
  }
  .submit-btn {
    margin-left: 90px;
  }
  .contact {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .formcontainer {
    margin-left: -450px;
  }
  .formcontainer form .input-box input,
  .formcontainer form .input-box textarea{
    width: 130%;
  }
  .formcontainer form .input-box{
    width: 250%;    
  }
  .icon-div {
    margin-left: 100px;
  }
  .items{
    display: flex;
    width: 50px;
  }
  
}
