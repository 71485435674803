/* .myskill-heading{
  color: #b34ee2;
  margin-top: 180px;
  margin-left: 670px;
} */
.progress-bar{
  margin-top: -150px;
  gap: 10px;
  /* margin-left: -500px; */
  margin-left: 350px;
}
.name{
  color: white;
  text-align: left;
}
.progress-bar p{
  color: white;
  margin-top: -50px;
  margin-left: 95%;
}
/* .tag{
  width: 30px;
  height: 20px;
  background-color:blue;
  padding: -20px;
} */
/* .progress-bar progress{
  animation: progress 0.4s ease-in-out forwards;
} 
 @keyframes progress{
  0%{
    width: 0;
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
} */
@media screen and (max-width:800px) {
  .progress-bar{
    margin-left: 5px;
 }
 
}