.flip-card {
    width: 50%;
    height: 30%;
    margin-top: 260px;
    margin-left: 365px;
}
  
.flip-card-front p {
    font-size: 2.5em;
    font-weight: 900;
    text-align: center;
    color: #c373e7;
}
  
.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
}
  
.flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
}
  
.flip-card-front, .flip-card-back {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border-radius: 1rem;
}
  
.flip-card-front {
    background-color: white;
}
  
.flip-card-back {
    background-color: #c373e7;
    color: white;
    transform: rotateY(180deg);
    text-align: justify;
    padding: 20px;
}
@media screen and (max-width: 800px){
    .flip-card{
        margin-left: 5%;
        width: 90%;
        margin-top: 360px;
    }
}